@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.logo {
  display: block;
  height: 107px;
  margin: 0 auto;
  width: 168px;
}
